<template>
  <div id="SMTPConnect">
    <Sheet
      :value="value"
      width="30vw"
      height="70vh"
      has-footer
      @input="closeSheet"
    >
      <!-- title -->

      <template #title> Connect SMTP</template>

      <!-- ... -->

      <template #default>
        <div class="smtp-connect-controls">
          <div>
            <ValidationObserver ref="form">
              <div class="row q-pl-md q-pr-md q-pt-md">
                <div class="col-6 q-pr-md">
                  <ValidationProvider
                    ref="providerRef"
                    v-slot="{ errors }"
                    name="Provider"
                    :rules="{ required: true }"
                  >
                    <SelectField
                      v-model="provider"
                      :label="'Provider'"
                      is-mandatory
                      :value="provider"
                      :options="providerList"
                      :is-clearable="false"
                      :error="errors[0]"
                      class="fieldList q-mb-sm"
                      @input="overviewChart"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-6">
                  <ValidationProvider
                    ref="settingsNameRef"
                    v-slot="{ errors }"
                    name="Settings Name"
                    :rules="{ required: true }"
                  >
                    <TextField
                      v-model="name"
                      label="Settings Name"
                      is-mandatory
                      :error="errors[0]"
                      class="q-mb-md q-mr-md"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </ValidationObserver>

            <div v-if="basicDetails" class="q-pl-md q-pr-md q-pt-sm">
              <template v-if="provider !== 'OUTLOOK'">
                <ValidationObserver ref="NotOUTLOOK">
                  <div class="row">
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Email"
                        :rules="{
                          required: provider !== 'OUTLOOK',
                          email: true,
                        }"
                      >
                        <TextField
                          v-model="email"
                          label="Email"
                          is-mandatory
                          tooltip="Authenticated two-factor email ID only"
                          :error="errors[0]"
                          class="q-mb-md q-mr-md"
                          @input="clearVerified"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="col-6">
                      <ValidationProvider
                        name="Password"
                        :rules="{
                          required: provider !== 'OUTLOOK',
                          password: true,
                        }"
                      >
                        <PasswordField
                          v-model="password"
                          label="Email App Password"
                          tooltip="Use your app password, not your email password"
                          is-mandatory
                          :is-readonly="verified"
                          class="q-mb-md"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </ValidationObserver>
              </template>

              <template v-if="provider === 'OUTLOOK'">
                <ValidationObserver ref="OUTLOOK">
                  <div class="row">
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="tenantAuthId"
                        :rules="{ required: provider === 'OUTLOOK' }"
                      >
                        <TextField
                          v-model="tenantAuthId"
                          label="tenant User id"
                          :error="errors[0]"
                          is-mandatory
                          class="q-mb-md q-mr-md"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="clientID"
                        :rules="{ required: provider === 'OUTLOOK' }"
                      >
                        <TextField
                          v-model="clientID"
                          label="client ID"
                          :error="errors[0]"
                          is-mandatory
                          class="q-mb-md q-mr-md"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="clientSecret"
                        :rules="{ required: provider === 'OUTLOOK' }"
                      >
                        <TextField
                          v-model="clientSecret"
                          label="client Secret"
                          :error="errors[0]"
                          is-mandatory
                          class="q-mb-md q-mr-md"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </ValidationObserver>
              </template>
              <div class="row">
                <CheckboxField
                  v-model="isIncoming"
                  label="Incoming"
                  description="To receive emails using the incoming server"
                  class="q-mb-md col-8"
                />
                <div
                  v-if="isIncoming"
                  class="col-4"
                  style="margin: auto auto 21px"
                >
                  <div v-if="!incomingVerified" @click="verifyMailCredentials">
                    <q-chip
                      outline
                      color="secondary"
                      class="cursor-pointer secondary-hover"
                    >
                      <q-avatar
                        icon="mdi-alert-circle-outline"
                        class="q-mr-sm"
                        color="secondary"
                        text-color="white"
                      />
                      Verify
                    </q-chip>
                  </div>
                  <q-chip
                    v-else
                    outline
                    color="green"
                    class="cursor-pointer secondary-hover"
                  >
                    <q-avatar
                      icon="mdi-check-circle"
                      class="q-mr-sm"
                      color="green"
                      text-color="white"
                    />
                    Verified
                  </q-chip>
                </div>
              </div>
              <div class="row">
                <CheckboxField
                  v-model="isOutgoing"
                  label="Outgoing"
                  description="To send emails using the outgoing server"
                  class="q-mb-md col-8"
                />
                <div
                  v-if="isOutgoing"
                  class="col-4"
                  style="margin: auto auto 21px"
                >
                  <div v-if="!outgoingVerified" @click="getEmailPopup">
                    <q-chip
                      outline
                      color="secondary"
                      class="cursor-pointer secondary-hover"
                    >
                      <q-avatar
                        icon="mdi-alert-circle-outline"
                        class="q-mr-sm"
                        color="secondary"
                        text-color="white"
                      />
                      Verify
                    </q-chip>
                  </div>
                  <q-chip
                    v-else
                    outline
                    color="green"
                    class="cursor-pointer secondary-hover"
                  >
                    <q-avatar
                      icon="mdi-check-circle"
                      class="q-mr-sm"
                      color="green"
                      text-color="white"
                    />
                    Verified
                  </q-chip>
                </div>
              </div>
              <CheckboxField
                v-model="enableSSL"
                label="Enable SSL"
                description="Connection between your email client and the email server is encrypted"
                class="q-mb-md"
              />

              <CheckboxField
                v-model="preference"
                label="Set as default"
                description="To enable email notifications to be pushed by default"
                class="q-mb-md"
              />
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <BaseButton
          :label="entry.id ? 'Update' : 'Connect'"
          color="primary"
          @click="createSMTPConnection"
        />
      </template>
    </Sheet>
    <GetEmail
      :value="getEmail"
      @handleYes="handleYes"
      @handleNo="getEmail = false"
      @hideSheet="getEmail = false"
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
// import NumberField from "@/components/common/form/number-field/NumberField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import GetEmail from "./components/GetEmail.vue";
import { integration } from "@/api/factory.js";

export default {
  name: "SMTPConnect",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    PasswordField,
    SelectField,
    CheckboxField,
    GetEmail,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    entry: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      getEmail: false,
      outgoingLoading: false,
      incomingLoading: false,
      incomingVerified: false,
      outgoingVerified: false,
      name: "",
      email: "",
      username: "",
      password: "",
      incomingServer: "",
      incomingPort: 0,
      outgoingServer: "",
      outgoingPort: 0,
      tenantAuthId: "",
      clientSecret: "",
      clientID: "",
      emailProtocol: "",
      serverAddress: "",
      toAddress: "",
      isOutgoing: false,
      isIncoming: false,
      enableSSL: false,
      preference: false,
      verified: false,
      provider: "",
      providerList: [
        {
          id: this.$nano.id(),
          label: "Gmail",
          value: "GMAIL",
        },
        {
          id: this.$nano.id(),
          label: "Yahoo",
          value: "YAHOO",
        },
        {
          id: this.$nano.id(),
          label: "Outlook",
          value: "OUTLOOK",
        },
      ],
      basicDetails: false,
      verifiedBtnColor: "#4caf50",
    };
  },

  watch: {
    entry: {
      immediate: true,
      handler() {
        this.reset();
        if (this.entry.id) {
          this.provider = this.entry.emailProvider;
          this.name = this.entry.name;
          this.email = this.entry.email;
          this.password = this.entry.password;
          this.enableSSL = this.entry.enableSSL;
          this.preference = this.entry.preference;
          this.basicDetails = true;
          this.clientID = this.entry.clientId;
          this.clientSecret = this.entry.clientSecret;
          this.tenantAuthId = this.entry.tenantAuthId;
          this.incomingVerified = this.entry.isIncomingVerified;
          this.outgoingVerified = this.entry.isOutgoingVerified;
          this.isOutgoing = this.entry.isOutgoing;
          this.isIncoming = this.entry.isIncoming;
        }
      },
    },
  },

  methods: {
    overviewChart() {
      this.basicDetails = true;
      this.name = "";
      this.email = "";
      this.username = "";
      this.password = "";
      this.incomingServer = "";
      this.incomingPort = 0;
      this.outgoingServer = "";
      this.outgoingPort = 0;
      this.clientID = "";
      this.tenantAuthId = "";
      this.clientSecret = "";
      this.toAddress = "";
      this.isOutgoing = false;
      this.isIncoming = false;
      this.incomingLoading = false;
      this.outgoingLoading = false;
      this.outgoingVerified = false;
      this.incomingVerified = false;
      this.verified = false;
    },
    isFormValid() {
      if (this.provider !== "outlook") {
        return (
          this.provider != "" &&
          this.email != "" &&
          this.password != "" &&
          this.name != ""
          // (!this.isOutgoing || this.toAddress != "")
        );
      } else {
        return (
          this.provider != "" &&
          this.tenantAuthId != "" &&
          this.clientID != "" &&
          this.clientSecret != "" &&
          this.password != "" &&
          this.name != ""
          // (!this.isOutgoing || this.toAddress != "")
        );
      }
    },
    closeSheet() {
      this.reset();
      this.$emit("close");
    },

    reset() {
      this.name = "";
      this.email = "";
      this.username = "";
      this.password = "";
      this.incomingServer = "";
      this.incomingPort = 0;
      this.outgoingServer = "";
      this.outgoingPort = 0;
      this.enableSSL = false;
      this.preference = false;
      this.provider = "";
      this.clientID = "";
      this.tenantAuthId = "";
      this.clientSecret = "";
      this.toAddress = "";
      this.isOutgoing = false;
      this.isIncoming = false;
      this.incomingLoading = false;
      this.outgoingLoading = false;
      this.outgoingVerified = false;
      this.incomingVerified = false;
      this.verified = false;
      this.basicDetails = false;
    },
    clearVerified() {
      this.incomingLoading = false;
      this.outgoingLoading = false;
      this.outgoingVerified = false;
      this.incomingVerified = false;
      this.verified = false;
    },
    async getEmailPopup() {
      this.getEmail = true;
    },
    handleYes(toMail) {
      this.toAddress = toMail;
      this.verifyMailCredentials();
      this.getEmail = false;
      this.outgoingLoading = true;
    },
    async verifyMailCredentials() {
      const areAllFieldsValid = await this.$refs.form.validate();
      if (this.provider !== "OUTLOOK") {
        const areAllFieldsValide = await this.$refs.NotOUTLOOK.validate();
        if (!areAllFieldsValide) {
          console.log(areAllFieldsValide);
          // return;
        }
      }
      if (this.provider == "OUTLOOK") {
        const areAllFieldsValid = await this.$refs.OUTLOOK.validate();
        if (!areAllFieldsValid) {
          console.log(areAllFieldsValid);
          // return;
        }
      }

      if (!areAllFieldsValid) {
        console.log(areAllFieldsValid);
        // return;
      }

      if (!this.isFormValid()) {
        return;
      }
      if (!this.outgoingLoading) {
        this.incomingLoading = true;
      }
      this.$store.commit("showLoadingBar");
      let input = {
        emailProvider: this.provider,
        email: this.email,
        password: this.password,
        tenantAuthId: this.tenantAuthId,
        clientId: this.clientID,
        clientSecret: this.clientSecret,
        criteria: {
          subject: this.isOutgoing ? "Test Email" : "",
          toAdd: this.isOutgoing ? this.toAddress : "",
          bodyContains: this.isOutgoing
            ? "Hello, \n \nThis is a test email to check if the email system is working correctly. Please confirm receipt of this email.\n \nThank you"
            : "",
        },
      };

      const { error } = await integration.verifyMailCredentials(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.warning("Please check email and password");
        this.$alert.error(error);
        this.incomingLoading = false;
        this.outgoingLoading = false;
        return;
      } else {
        this.verified = true;
        if (this.outgoingLoading) {
          this.outgoingVerified = true;
        } else {
          this.incomingVerified = true;
        }
        if (this.entry.id) {
          this.$store.commit("showLoadingBar");

          let input = {
            settingName: this.name,
            emailProvider: this.provider,
            emailId: this.email,
            password: this.password,
            clientId: this.clientID,
            clientSecret: this.clientSecret,
            tenantAuthId: this.tenantAuthId,
            incomingVerified: this.incomingVerified
              ? 1
              : this.isIncoming
              ? 2
              : 0,
            outgoingVerified: this.outgoingVerified
              ? 1
              : this.isOutgoing
              ? 2
              : 0,
            EnableSSL: this.enableSSL,
            Preference: this.preference ? 1 : 0,
          };

          const { error } = await integration.updateSMTPConnection(
            this.entry.id,
            input
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
        }

        this.$alert.success(`Email verified`);
      }
      this.incomingLoading = false;
      this.outgoingLoading = false;
    },

    async createSMTPConnection() {
      const areAllFieldsValid = await this.$refs.form.validate();
      if (this.provider !== "OUTLOOK") {
        const areAllFieldsValide = await this.$refs.NotOUTLOOK.validate();
        if (!areAllFieldsValide) {
          console.log(areAllFieldsValide);
          // return;
        }
      }
      if (this.provider == "OUTLOOK") {
        const areAllFieldsValid = await this.$refs.OUTLOOK.validate();
        if (!areAllFieldsValid) {
          console.log(areAllFieldsValid);
          // return;
        }
      }

      if (!areAllFieldsValid) {
        console.log(areAllFieldsValid);
        // return;
      }

      if (!this.isFormValid()) {
        return;
      }
      this.$store.commit("showLoadingBar");

      let input = {
        settingName: this.name,
        emailProvider: this.provider,
        emailId: this.email,
        password: this.password,
        clientId: this.clientID,
        clientSecret: this.clientSecret,
        tenantAuthId: this.tenantAuthId,
        incomingVerified: this.isIncoming
          ? this.incomingVerified
            ? 1
            : this.isIncoming
            ? 2
            : 0
          : 0,
        outgoingVerified: this.isOutgoing
          ? this.outgoingVerified
            ? 1
            : this.isOutgoing
            ? 2
            : 0
          : 0,
        EnableSSL: this.enableSSL,
        Preference: this.preference ? 1 : 0,
      };

      if (this.entry.id) {
        const { error } = await integration.updateSMTPConnection(
          this.entry.id,
          input
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error } = await integration.createSMTPConnection(input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      }

      if (this.entry.id) {
        this.$alert.success(`Connection updated`);
      } else {
        this.$alert.success(`Connection created`);
      }
      this.reset();
      this.closeSheet();
    },
  },
};
</script>
<style lang="scss" scoped>
#sheet {
  .smtp-connect-controls {
    .field {
      margin-bottom: 16px;
    }
  }
}
.verify-email {
  margin: auto;
}
.actionsBtns {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
#select-field.fieldList .select {
  color: #1e293b !important;
}
#password-field {
  width: 94%;
}
#select-field {
  #field-wrapper {
    height: 50px !important;
  }
}
</style>
