<template>
  <Sheet
    :value="value"
    has-footer
    no-padding
    width="100vw"
    @input="colseConnection"
  >
    <!-- title -->

    <template #title>API Connection</template>

    <!-- ... -->

    <!-- form -->

    <template #default>
      <div class="q-pa-md">
        <div class="row items-center">
          <div class="col">
            <div id="api-input">
              <!-- <div class="avatar">R1</div> -->
              <SelectField
                v-if="false"
                v-model="method"
                is-mandatory
                :options="[]"
                :is-clearable="false"
              />
              <div class="item-list">
                <div class="row items-center">
                  <div class="label">{{ method }}</div>
                  <BaseIcon
                    name="eva-chevron-down"
                    inherit-color
                    class="icon"
                  />
                </div>

                <q-menu
                  fit
                  auto-close
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <ListItem
                    v-for="item in methodList"
                    :key="item.id"
                    :is-selected="method === item.value"
                    :label="item.label"
                    class="text-capitalize"
                    @click="method = item.value"
                  />
                </q-menu>
              </div>
              <BaseSeparator is-vertical class="q-mx-sm" />

              <!-- api url -->

              <div class="api-url">
                <input v-model="apiUrl" placeholder="URL" />
              </div>

              <!-- ... -->
            </div>
          </div>

          <div class="col-auto q-ml-sm">
            <BaseButton
              label="send"
              color="secondary"
              class="q-pa-xs"
              @click="testApiConnection"
            />
          </div>
        </div>
        <Tabs v-model="tab" :tabs="tabs" class="tabs" />

        <div class="q-mb-md input">
          <BaseScrollbar class="q-pb-sm" height="calc(100vh - 456px)">
            <Parameter
              v-if="tab === 'params'"
              :parameter="parameter"
              @add="addRow"
              @delete="deleteRow"
            >
            </Parameter>
            <Authorization
              v-if="tab === 'authorization'"
              :auth2="auth2"
              :type.sync="authorizationType"
            ></Authorization>
            <Headers v-if="tab === 'headers'"></Headers>
            <Body v-if="tab === 'body'"></Body>
          </BaseScrollbar>
        </div>

        <div class="q-mb-md">
          <BaseScrollbar class="q-pb-sm" height="calc(100vh - 456px)">
            <div class="responseTitle">Response</div>
            <div
              v-if="!response && !apiFunctionResponse"
              class="row items-center justify-center"
            >
              <div class="empty description">
                Enter the URL and click Send to get a response
              </div>
            </div>
            <div v-if="response" class="response">
              {{ response }}
            </div>
            <div v-if="apiFunctionResponse" class="response">
              {{ apiFunctionResponse }}
            </div>
          </BaseScrollbar>
        </div>

        <AddName
          v-model="showNameModal"
          :name.sync="name"
          @close="
            showNameModal = false;
            name = '';
          "
          @save="saveApi"
        />
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        label="cancel"
        is-flat
        class="q-mr-sm"
        @click="colseConnection"
      />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>
<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import ListItem from "@/components/common/ListItem.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import Parameter from "./components/Parameter";
import Authorization from "./components/Authorization";
import Headers from "./components/Headers";
import Body from "./components/Body";
import AddName from "./components/AddName";
import { connector } from "@/api/factory.js";

export default {
  name: "APIConnection",

  components: {
    Sheet,
    SelectField,
    ListItem,
    Tabs,
    Parameter,
    Authorization,
    Headers,
    Body,
    AddName,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    entry: {
      type: Object,
      default: () => {},
    },

    connectorId: {
      type: [String, Number],
      default: 0 || "",
    },
  },

  data() {
    return {
      apiUrl: "",
      method: "POST",
      methodList: [
        {
          id: this.$nano.id(),
          label: "post",
          value: "POST",
        },
        {
          id: this.$nano.id(),
          label: "get",
          value: "GET",
        },
        {
          id: this.$nano.id(),
          label: "put",
          value: "PUT",
        },
        {
          id: this.$nano.id(),
          label: "patch",
          value: "PATCH",
        },
        {
          id: this.$nano.id(),
          label: "delete",
          value: "DELETE",
        },
      ],
      tab: "params",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Params",
          value: "params",
        },
        {
          id: this.$nano.id(),
          label: "Authorization",
          value: "authorization",
        },
        {
          id: this.$nano.id(),
          label: "Headers",
          value: "headers",
        },
        {
          id: this.$nano.id(),
          label: "Body",
          value: "body",
        },
      ],
      auth2: {
        tokenName: "",
        grantType: "",
        accessTokenURL: "",
        clientId: "",
        clientSecret: "",
        username: "",
        password: "",
        scope: "",
      },
      response: "",
      input: "",
      showNameModal: false,
      name: "",
      authorizationType: "",
      apiInputFunction: "",
      apiFunctionResponse: "",
      //params
      parameter: [
        {
          id: this.$nano.id(),
          key: "",
          value: "",
          description: "",
        },
      ],
    };
  },

  watch: {
    entry: {
      immediate: true,
      handler() {
        if (this.entry.id) {
          this.name = this.entry.name;
          this.authorizationType = this.entry.connectorType;
          if (this.authorizationType === "oauth2") {
            this.tab = "authorization";
            let connectionString = JSON.parse(this.entry.connectionString);
            // console.log(connectionString);
            this.auth2.grantType = connectionString.grantType;
            this.auth2.clientId = connectionString.clientId;
            this.auth2.clientSecret = connectionString.clientSecret;
            this.auth2.username = connectionString.username;
            this.auth2.password = connectionString.password;
            this.apiUrl = connectionString.baseUrl;
          }
        }
      },
    },
  },

  methods: {
    colseConnection() {
      this.$emit("close");
      this.showNameModal = false;
      this.name = "";
      this.authorizationType = "";
      this.auth2 = {
        tokenName: "",
        grantType: "",
        accessTokenURL: "",
        clientId: "",
        clientSecret: "",
        username: "",
        password: "",
        scope: "",
      };
      this.apiUrl = "";
      this.tab = "params";
    },

    async testApiConnection() {
      if (this.connectorId) {
        this.testApiFunction();
      } else {
        this.testConnection();
      }
    },

    async testConnection() {
      let input = {
        clientId: this.auth2.clientId,
        clientSecret: this.auth2.clientSecret,
        refreshToken: "",
        username: this.auth2.username,
        password: this.auth2.password,
        accessToken: "",
        baseUrl: this.apiUrl,
        code: "",
        redirect_uri: "",
        assertion: "",
        grantType: this.auth2.grantType,
      };

      this.input = input;

      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.testoauth2Connection(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`Connection tested successfully`);
      if (payload) {
        // console.log(payload);
        this.response = payload;
      }
    },

    async testApiFunction() {
      let column = {};
      this.parameter.forEach((item) => {
        this.$set(column, item.key, item.value);
      });
      let input = {
        connectorId: this.connectorId,
        endPoint: this.apiUrl,
        payloadMapping: JSON.stringify(column),
        type: this.method,
      };

      this.apiInputFunction = input;

      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.testApiFunction(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`Function tested successfully`);
      if (payload) {
        // console.log(payload);
        this.apiFunctionResponse = payload;
        console.log(
          this.apiFunctionResponse[Object.keys(this.apiFunctionResponse)[3]]
        );
      }
    },

    save() {
      if (this.connectorId) {
        this.saveApiFunction();
      } else {
        this.saveConnection();
      }
    },

    saveConnection() {
      if (this.entry.id) {
        this.createConnection();
      } else {
        this.showNameModal = true;
      }
    },

    saveApi() {
      if (this.connectorId) {
        this.addApiFunction();
      } else {
        this.createConnection();
      }
    },

    async createConnection() {
      // const areAllFieldsValid = await this.$refs.form.validate();

      // if (!areAllFieldsValid) {
      //   return;
      // }

      this.$store.commit("showLoadingBar");
      let input = {
        name: this.name,
        connectorType: this.authorizationType,
        credentialJson: JSON.stringify(this.input),
        dynamicCredentialJson: "",
        responseStatus: "",
        responseStatusCode: "",
        responseBody: "",
      };

      if (this.connectorId) {
        this.connectLookUp();
      } else {
        if (this.entry.id) {
          const { error } = await connector.updateConnector(
            this.entry.id,
            input
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
        } else {
          const { error } = await connector.addConnector(input);

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
        }

        if (this.entry.id) {
          this.$alert.success(`Connection updated`);
        } else {
          this.$alert.success(`Connection created`);
        }
      }

      this.colseConnection();
    },

    async connectLookUp() {
      this.$store.commit("showLoadingBar");

      let input = {
        name: this.name,
        connectorId: this.connectorId,
        hubPoint: this.apiUrl,
        inDataPoint: JSON.stringify(this.input),
        outDataPoint: JSON.stringify(this.response),
        conditionDataPoint: "",
        executionType: this.method,
      };
      if (this.entry.id) {
        const { error } = await connector.updateLookUp(this.entry.id, input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error, payload } = await connector.connectLookUp(input);
        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        console.log(payload);
      }
      if (this.entry.id) {
        this.$alert.success(`Lookup edited successfully`);
      } else {
        this.$alert.success(`Lookup connected successfully`);
      }
    },

    addRow() {
      this.parameter.push({
        id: this.$nano.id(),
        key: "",
        value: "",
        description: "",
      });
    },

    deleteRow(index) {
      this.parameter.splice(index, 1);
    },

    saveApiFunction() {
      if (this.entry.id) {
        this.addApiFunction();
      } else {
        this.showNameModal = true;
      }
    },

    async addApiFunction() {
      this.$store.commit("showLoadingBar");
      let inData = [];
      let inDataPoint = JSON.parse(this.apiInputFunction.payloadMapping);
      for (let key in inDataPoint) {
        inData.push(key);
      }

      let outData = [];
      console.log(
        this.apiFunctionResponse[Object.keys(this.apiFunctionResponse)[3]]
      );
      let outDataPoint =
        this.apiFunctionResponse[Object.keys(this.apiFunctionResponse)[3]];
      for (let key in outDataPoint) {
        outData.push(key);
      }

      let input = {
        name: this.name,
        connectorId: this.connectorId,
        hubPoint: this.apiUrl,
        inDataPoint: JSON.stringify(inData),
        outDataPoint: JSON.stringify(outData),
        conditionDataPoint: "",
        executionType: this.method,
      };

      // if (this.lookup.id) {
      //   const { error } = await connector.updateLookUp(this.lookup.id, input);

      //   this.$store.commit("hideLoadingBar");

      //   if (error) {
      //     this.$alert.error(error);
      //     return;
      //   }
      // } else {
      const { error, payload } = await connector.connectLookUp(input);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      // }
      // if (this.lookup.id) {
      //   this.$alert.success(`Lookup edited successfully`);
      // } else {
      this.$alert.success(`Lookup added successfully`);
      // }
      this.colseConnection();
    },
  },
};
</script>

<style lang="scss" scoped>
#api-input {
  display: flex;
  align-items: center;
  border: 1px solid var(--divider-color);
  border-radius: 4px;
  padding: 7px;
  position: relative;
  //   margin-bottom: 24px;

  .api-url {
    flex: 1;
  }

  .item-list {
    padding: 4px 4px 4px 4px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;

    .label {
      flex: 1;
      color: var(--secondary);
      margin-right: 8px;
    }

    .icon {
      color: var(--icon-color-inverted);
    }
  }
}

.tabs {
  width: auto;
  border-bottom: 1px solid var(--divider-color);
}

.input {
  border-bottom: 1px solid var(--divider-color);
}

.responseTitle {
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 24px;
  // height: 56px;
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
  color: var(--title-3-color);
}

.empty,
.response {
  color: var(--icon-color-inverted);
  margin-top: 10px;
  text-align: justify;
  // font-size: 12px;
}
</style>
