<template>
  <div class="body q-my-md">
    <div class="row items-center q-mb-md">
      <div class="col-11">
        <SingleChoiceField
          v-model="bodyType"
          is-mandatory
          :options="bodyTypes"
          :options-per-line="6"
        />
      </div>
      <div v-if="showAdvance" class="col-1 q-mt-md">
        <div class="item-list">
          <div class="row items-center">
            <div class="label">{{ method }}</div>
            <BaseIcon name="eva-chevron-down" inherit-color class="icon" />
          </div>

          <q-menu
            fit
            auto-close
            transition-show="scale"
            transition-hide="scale"
          >
            <ListItem
              v-for="item in methodList"
              :key="item.id"
              :is-selected="method === item.value"
              :label="item.label"
              class="text-capitalize"
              @click="method = item.value"
            />
          </q-menu>
        </div>
      </div>
    </div>

    <div
      v-if="bodyType === 'none'"
      class="row items-center justify-center description"
    >
      This request does not have a body
    </div>

    <div v-if="bodyType === 'form-data'" class="row items-center">
      <BaseScrollbar class="q-pb-sm">
        <table>
          <thead>
            <tr>
              <th class="extra-small"></th>
              <th
                v-for="column in tableColumns"
                :key="column.id"
                class="medium"
              >
                {{ column.label }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td>
                <CheckboxField
                  v-if="row.showCheckBox"
                  v-model="row.hasChecked"
                  label=""
                  description=""
                  class="q-ml-md"
                />
              </td>
              <td v-for="column in tableColumns" :key="column.id">
                <div class="row items-center">
                  <div class="col">
                    <template v-if="column.value === 'Key'">
                      <SelectField
                        v-model="row[column.value]"
                        is-mandatory
                        :options="valueTypes"
                      />
                    </template>

                    <template v-else>
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="column.label"
                        :rules="{ required: true }"
                      >
                        <TextField
                          v-model="row[column.value]"
                          is-mandatory
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </template>
                  </div>
                  <div
                    v-if="row.Key || row.Value || row.Description"
                    class="col-auto"
                  >
                    <template v-if="column.value === 'Description'">
                      <BaseActionButton
                        is-flat
                        color="red"
                        icon="eva-trash-2-outline"
                        no-border
                        class="q-mx-sm"
                      />
                    </template>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </BaseScrollbar>
    </div>

    <div v-if="bodyType === 'form-urlencoded'" class="row items-center">
      <BaseScrollbar class="q-pb-sm">
        <table>
          <thead>
            <tr>
              <th class="extra-small"></th>
              <th
                v-for="column in tableColumns"
                :key="column.id"
                class="medium"
              >
                {{ column.label }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td>
                <CheckboxField
                  v-if="row.showCheckBox"
                  v-model="row.hasChecked"
                  label=""
                  description=""
                  class="q-ml-md"
                />
              </td>
              <td v-for="column in tableColumns" :key="column.id">
                <div class="row items-center">
                  <div class="col">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :name="column.label"
                      :rules="{ required: true }"
                    >
                      <TextField
                        v-model="row[column.value]"
                        is-mandatory
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    v-if="row.Key || row.Value || row.Description"
                    class="col-auto"
                  >
                    <template v-if="column.value === 'Description'">
                      <BaseActionButton
                        is-flat
                        color="red"
                        icon="eva-trash-2-outline"
                        no-border
                        class="q-mx-sm"
                      />
                    </template>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </BaseScrollbar>
    </div>

    <div v-if="bodyType === 'raw'" class="row items-center full-width">
      <div class="col-12">
        <TextAreaField v-model="raw" is-mandatory />
      </div>
    </div>

    <div v-if="bodyType === 'binary'" class="row items-center q-mb-md">
      <BaseButton label="Select File" @click="$refs.dropzone.click()" />
      <input ref="dropzone" type="file" class="file" />
    </div>

    <div v-if="bodyType === 'GraphQL'" class="row items-center">
      <div class="col-6 q-mr-md">
        <FormFieldLabel label="QUERY" />

        <TextAreaField v-model="raw" is-mandatory />
      </div>
      <div class="col-5">
        <FormFieldLabel
          label="GRAPHQL VARIABLES"
          :tooltip="'Define variables in JSON format to use in the query'"
        />

        <TextAreaField v-model="raw" is-mandatory />
      </div>
    </div>

    <!-- <FormFieldError v-if="error" :error="error" /> -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
// import TableField from "@/components/common/form/table-field/TableField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
// import FormFieldError from "@/components/common/form/FormFieldError.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import ListItem from "@/components/common/ListItem.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

export default {
  name: "Body",

  components: {
    ValidationProvider,
    SingleChoiceField,
    FormFieldLabel,
    // FormFieldError,
    TextField,
    CheckboxField,
    SelectField,
    TextAreaField,
    ListItem,
  },

  data() {
    return {
      bodyType: "none",
      bodyTypes: [
        {
          id: this.$nano.id(),
          label: "none",
          value: "none",
        },
        {
          id: this.$nano.id(),
          label: "form-data",
          value: "form-data",
        },
        {
          id: this.$nano.id(),
          label: "x-www-form-urlencoded",
          value: "form-urlencoded",
        },
        {
          id: this.$nano.id(),
          label: "raw",
          value: "raw",
        },
        {
          id: this.$nano.id(),
          label: "binary",
          value: "binary",
        },
        {
          id: this.$nano.id(),
          label: "GraphQL",
          value: "GraphQL",
        },
      ],
      tableColumns: [
        {
          id: this.$nano.id(),
          label: "Key",
          value: "Key",
        },
        {
          id: this.$nano.id(),
          label: "Value",
          value: "Value",
        },
        {
          id: this.$nano.id(),
          label: "Description",
          value: "Description",
        },
      ],
      rows: [
        {
          id: this.$nano.id(),
          Key: "",
          Value: "",
          Description: "",
          showCheckBox: false,
          hasChecked: false,
        },
      ],
      formData: [
        {
          id: this.$nano.id(),
          Key: "",
          Value: "",
          valueType: "",
          Description: "",
          showCheckBox: false,
          hasChecked: false,
        },
      ],
      error: "",
      raw: "",
      methodList: [
        {
          id: this.$nano.id(),
          label: "Auto Fetch",
          value: "Auto Fetch",
        },
        {
          id: this.$nano.id(),
          label: "No Schema",
          value: "No Schema",
        },
      ],
      method: "Auto Fetch",
      valueTypes: [
        {
          id: this.$nano.id(),
          label: "Text",
          value: "Text",
        },
        {
          id: this.$nano.id(),
          label: "File",
          value: "File",
        },
      ],
    };
  },

  computed: {
    showAdvance() {
      if (this.bodyType === "GraphQL" || this.bodyType === "raw") {
        return true;
      }
      return false;
    },
  },

  watch: {
    bodyType() {
      if (this.bodyType === "GraphQL") {
        this.methodList = [
          {
            id: this.$nano.id(),
            label: "Auto Fetch",
            value: "Auto Fetch",
          },
          {
            id: this.$nano.id(),
            label: "No Schema",
            value: "No Schema",
          },
        ];
        this.method = "Auto Fetch";
      } else if (this.bodyType === "raw") {
        this.methodList = [
          {
            id: this.$nano.id(),
            label: "JavaScript",
            value: "JavaScript",
          },
          {
            id: this.$nano.id(),
            label: "Text",
            value: "Text",
          },
          {
            id: this.$nano.id(),
            label: "JSON",
            value: "JSON",
          },
          {
            id: this.$nano.id(),
            label: "HTML",
            value: "HTML",
          },
          {
            id: this.$nano.id(),
            label: "XML",
            value: "XML",
          },
        ];
        this.method = "Text";
      }
    },

    rows: {
      deep: true,
      handler() {
        if (this.rows.length) {
          if (
            this.rows[this.rows.length - 1].Key ||
            this.rows[this.rows.length - 1].Value ||
            this.rows[this.rows.length - 1].Description
          ) {
            this.rows[this.rows.length - 1].showCheckBox = true;
            this.rows[this.rows.length - 1].hasChecked = true;
            this.rows.push({
              id: this.$nano.id(),
              Key: "",
              Value: "",
              Description: "",
              showCheckBox: false,
              hasChecked: false,
            });
          }
        }
      },
    },
  },

  methods: {},
};
</script>

<style lang="scss">
.body {
  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
    text-align: justify;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 40px;
    }

    th {
      padding: 4px 4px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.extra-small {
        width: 20px;
      }

      &.small {
        width: 70px;
      }

      &.medium {
        width: 140px;
      }

      &.large {
        width: 210px;
      }

      &.actions {
        width: 48px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }

    td {
      padding: 4px;
      vertical-align: top;
    }

    td .label {
      display: flex;
      align-items: center;
      height: 40px;
      padding-left: 4px;
      color: var(--icon-color);
    }
  }

  .item-list {
    padding: 4px 4px 4px 4px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;

    .label {
      flex: 1;
      color: var(--secondary);
      margin-right: 8px;
    }

    .icon {
      color: var(--icon-color-inverted);
    }
  }

  .file {
    // width: 100%;
    // height: 100%;
    opacity: 0;
    // position: absolute;
    cursor: pointer;
  }
}
</style>
