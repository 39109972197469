import { render, staticRenderFns } from "./TeamsConnect.vue?vue&type=template&id=0793d2b8&"
import script from "./TeamsConnect.vue?vue&type=script&lang=js&"
export * from "./TeamsConnect.vue?vue&type=script&lang=js&"
import style0 from "./TeamsConnect.vue?vue&type=style&index=0&id=0793d2b8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports