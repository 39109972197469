<template>
  <Sheet
    :value="value"
    width="50vw"
    height="70vh"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title> SQL Connect</template>

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <div class="field-wrapper">
            <ValidationProvider
              v-slot="{ errors }"
              name="Settings Name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="name"
                label="Settings Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Connection String"
              :rules="{ required: true }"
            >
              <TextAreaField
                v-model="connectionString"
                label="Connection String"
                rows="5"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <BaseButton
              label="Test Connection"
              color="secondary"
              style="float: right"
              @click="testConnection"
            />
          </div>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton
        :label="entry.id ? 'Update' : 'Connect'"
        color="primary"
        @click="createSQLConnection"
      />
    </template>
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import { connector } from "@/api/factory.js";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "SQLConnect",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    TextAreaField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    entry: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      name: "",
      connectionString: "",
    };
  },

  watch: {
    entry: {
      immediate: true,
      handler() {
        this.reset();
        if (this.entry.id) {
          this.name = this.entry.name;
          this.connectionString = this.entry.connectionString;
        }
      },
    },
  },

  methods: {
    closeSheet() {
      this.$emit("close");
    },

    reset() {
      this.name = "";
      this.connectionString = "";
    },

    async testConnection() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      let input = { connectionString: this.connectionString, Type: "SQL" };

      this.$store.commit("showLoadingBar");

      const { error } = await connector.testdatabaseconnection(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`Connection tested successfully`);
    },

    async createSQLConnection() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.$store.commit("showLoadingBar");
      let input = {
        name: this.name,
        connectorType: "SQL",
        credentialJson: JSON.stringify({
          connectionString: this.connectionString,
        }),
        dynamicCredentialJson: "",
        responseStatus: "",
        responseStatusCode: "",
        responseBody: "",
      };
      if (this.entry.id) {
        const { error } = await connector.updateConnector(this.entry.id, input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error } = await connector.addConnector(input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      }

      if (this.entry.id) {
        this.$alert.success(`Connection updated`);
      } else {
        this.$alert.success(`Connection created`);
      }
      this.reset();
      this.closeSheet();
    },
  },
};
</script>
<style lang="scss">
#single-choice-field.singleChoice {
  .options-wrapper {
    border-top: none !important;
    padding-top: 0px !important;
  }
}
</style>
