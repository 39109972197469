<template>
  <Sheet
    :value="value"
    width="30vw"
    height="70vh"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title> Slack Connect</template>

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <div class="field-wrapper">
            <ValidationProvider
              v-slot="{ errors }"
              name="Name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="name"
                label="Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Client Id"
              :rules="{ required: true }"
            >
              <TextField
                v-model="clientId"
                label="Client Id"
                is-mandatory
                :is-readonly="entry.id ? true : false"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Secret Id"
              :rules="{ required: true }"
            >
              <PasswordField
                v-model="secretId"
                label="Secret Id"
                is-mandatory
                :is-readonly="entry.id ? true : false"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton
        :label="entry.id ? 'Update' : 'Connect'"
        color="primary"
        @click="connectSlack"
      />
    </template>
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import { connector } from "@/api/factory.js";
// import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";

export default {
  name: "SlackConnect",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    PasswordField,
    // TextAreaField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    entry: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      name: "",
      clientId: "",
      secretId: "",
      redirectUrl: "",
    };
  },

  watch: {
    entry: {
      immediate: true,
      handler() {
        this.reset();
        if (this.entry.id) {
          // console.log(this.entry);
          let secretId = JSON.parse(this.entry.credentialJson);
          this.name = this.entry.name;
          this.clientId = this.entry.clientId;
          this.secretId = secretId.clientSecret;
        }
      },
    },
  },

  methods: {
    closeSheet() {
      this.$emit("close");
    },

    reset() {
      this.name = "";
      this.clientId = "";
      this.secretId = "";
    },

    async connectSlack() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.$store.commit("showLoadingBar");
      if (this.entry.id) {
        this.createSlackConnection();
        return;
      }

      let redirect = window.location.origin;
      this.redirectUrl = `${redirect}/slack/authenticate`;

      if (process.env.VUE_APP_PATH && process.env.VUE_APP_PATH.length > 1) {
        this.redirectUrl = `${redirect}${process.env.VUE_APP_PATH}/slack/authenticate`;
      }
      // this.redirectUrl = location.origin + "/app/slack/authenticate";
      let link = `https://slack.com/oauth/v2/authorize?client_id=${
        this.clientId
      }&scope=chat:write chat:write.customize files:read files:write links.embed:write links:read links:write channels:read remote_files:read remote_files:share remote_files:write groups:read users.profile:read users:read users:read.email users:write&state=${this.$nano.id()}&redirect_uri=${
        this.redirectUrl
      }`;
      window.open(link, "_blank");

      const timer = setInterval(() => {
        const token = localStorage.getItem("slackSignToken");
        if (token) {
          clearInterval(timer);
          localStorage.removeItem("slackSignToken");
          this.createSlackConnection(token);
        }
      });
    },

    async createSlackConnection(token) {
      if (this.entry.id) {
        let input = {
          name: this.name,
        };
        const { error } = await connector.updateConnector(this.entry.id, input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        let input = {
          name: this.name,
          connectorType: "slack",
          clientid: this.clientId,
          clientsecret: this.secretId,
          code: token,
          redirecturl: this.redirectUrl,
        };
        const { error } = await connector.addConnection(input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      }

      if (this.entry.id) {
        this.$alert.success(`Connection updated`);
      } else {
        this.$alert.success(`Connection created`);
      }
      this.reset();
      this.closeSheet();
    },
  },
};
</script>
<style lang="scss">
#single-choice-field.singleChoice {
  .options-wrapper {
    border-top: none !important;
    padding-top: 0px !important;
  }
}
</style>
