<template>
  <Confirm
    :value="value"
    icon="mdi-note-outline"
    icon-color="warning"
    @input="handleCancel"
  >
    <template #title>Name</template>

    <template #default>
      <div class="q-py-md">
        <ValidationObserver ref="form">
          <ValidationProvider
            v-slot="{ errors }"
            name="Name"
            :rules="{ required: true }"
          >
            <TextField
              :value="name"
              is-mandatory
              label="Name"
              :error="errors[0]"
              @input="updateName"
            />
          </ValidationProvider>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="cancel"
        class="q-mr-sm"
        @click="handleCancel"
      />
      <BaseButton label="save" @click="handleSave" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "AddName",

  components: { Confirm, ValidationProvider, TextField, ValidationObserver },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    name: {
      type: String,
      default: "",
    },
  },

  methods: {
    updateName(name) {
      this.$emit("update:name", name);
    },

    handleCancel() {
      this.$emit("close", false);
    },

    async handleSave() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }

      this.$emit("save");
    },
  },
};
</script>

<style lang="scss" scoped></style>
