<template>
  <Confirm
    :value="value"
    icon="eva-email-outline"
    icon-color="orange"
    @input="hideSheet"
  >
    <template #title>verify Email</template>

    <template #default>
      <div class="q-py-md">
        <p>Enter an email address to check the test email</p>

        <!-- <p>A sample mail send to this mail id</p> -->
        <ValidationObserver ref="form">
          <ValidationProvider
            v-slot="{ errors }"
            name="To Address"
            :rules="{ required: true, email: true }"
          >
            <TextField
              v-model="toAddress"
              label="Add To Address for testing"
              :error="errors[0]"
              placeholder="sample@gmail.com"
              tooltip="A sample mail send to this mail id"
              is-mandatory
              class="q-mb-md q-mr-md"
            />
          </ValidationProvider>
        </ValidationObserver>
      </div>
    </template>
    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="orange"
        @click="Cancel"
      />
      <BaseButton label="Verify" color="orange" @click="Verify" />
    </template>
  </Confirm>
</template>

<script>
import TextField from "@/components/common/form/text-field/TextField.vue";
import Confirm from "@/components/common/popup/Confirm.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "GetEmail",

  components: { Confirm, TextField, ValidationProvider, ValidationObserver },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      toAddress: "",
    };
  },

  methods: {
    Cancel() {
      this.$emit("handleNo", false);
    },

    hideSheet() {
      this.$emit("hideSheet", false);
    },

    async Verify() {
      const areAllFieldsValid = await this.$refs.form.validate();
      if (!areAllFieldsValid) {
        return;
      }
      this.$emit("handleYes", this.toAddress);
    },
  },
};
</script>

<style lang="scss" scoped></style>
