<template>
  <div id="table-field" class="q-my-md">
    <FormFieldLabel label="Query Params" />

    <!-- table -->

    <BaseScrollbar class="q-pb-sm">
      <table>
        <thead>
          <tr>
            <th v-for="column in tableColumns" :key="column.id" class="medium">
              {{ column.label }}
            </th>
            <th class="actions">
              <BaseActionButton
                v-tooltip:top="'add row'"
                is-flat
                color="primary"
                icon="eva-plus"
                no-border
                @click="$emit('add')"
              />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, index) in parameter" :key="row.id">
            <td v-for="column in tableColumns" :key="column.id">
              <ValidationProvider
                v-slot="{ errors }"
                :name="column.label"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="row[column.value]"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </td>
            <td class="actions">
              <BaseActionButton
                v-tooltip="'delete row'"
                is-flat
                color="red"
                icon="eva-trash-outline"
                no-border
                @click="$emit('delete', index)"
              />
            </td>

            <!-- actions -->

            <!-- <td>
              <div class="row items-center justify-center" style="height: 46px">
                <BaseActionButton
                  is-flat
                  color="red"
                  icon="eva-trash-2-outline"
                  no-border
                  @click="deleteRow(row)"
                />
              </div>
            </td> -->

            <!-- ... -->
          </tr>
        </tbody>
      </table>
    </BaseScrollbar>

    <!-- ... -->

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
// import TableField from "@/components/common/form/table-field/TableField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "Parameter",

  components: { ValidationProvider, FormFieldLabel, FormFieldError, TextField },

  props: {
    parameter: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tableColumns: [
        {
          id: this.$nano.id(),
          label: "Key",
          value: "key",
        },
        {
          id: this.$nano.id(),
          label: "Value",
          value: "value",
        },
        {
          id: this.$nano.id(),
          label: "Description",
          value: "description",
        },
      ],
      rows: [
        {
          id: this.$nano.id(),
          Key: "",
          Value: "",
          Description: "",
        },
      ],
      error: "",
    };
  },

  methods: {},
};
</script>

<style lang="scss">
#table-field {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 140px;
      }

      &.large {
        width: 210px;
      }

      &.actions {
        width: 48px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }

    td {
      padding: 4px;
      vertical-align: top;
    }

    td .label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 4px;
      color: var(--icon-color);
    }
  }
}
</style>
