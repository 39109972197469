<template>
  <Sheet
    :value="value"
    width="30vw"
    height="70vh"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title> Teams Connect</template>

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <div class="field-wrapper">
            <ValidationProvider
              v-slot="{ errors }"
              name="Name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="name"
                label="Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Client Id"
              :rules="{ required: true }"
            >
              <TextField
                v-model="clientId"
                label="Client Id"
                is-mandatory
                :is-readonly="entry.id ? true : false"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Tenant Id"
              :rules="{ required: true }"
            >
              <TextField
                v-model="tenantId"
                label="Tenant Id"
                is-mandatory
                :is-readonly="entry.id ? true : false"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Secret Id"
              :rules="{ required: true }"
            >
              <PasswordField
                v-model="secretId"
                label="Secret Id"
                is-mandatory
                :is-readonly="entry.id ? true : false"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton
        :label="entry.id ? 'Update' : 'Connect'"
        color="primary"
        @click="connectTeams"
      />
    </template>
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import { connector } from "@/api/factory.js";
// import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";

export default {
  name: "TeamsConnect",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    PasswordField,
    // TextAreaField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    entry: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      name: "",
      clientId: "",
      secretId: "",
      redirectUrl: "",
      tenantId: "",
    };
  },

  watch: {
    entry: {
      immediate: true,
      handler() {
        this.reset();
        if (this.entry.id) {
          // console.log(this.entry);
          let secretId = JSON.parse(this.entry.credentialJson);
          this.name = this.entry.name;
          this.clientId = this.entry.clientId;
          this.secretId = secretId.clientSecret;
          this.tenantId = secretId.tenantid;
        }
      },
    },
  },

  methods: {
    closeSheet() {
      this.$emit("close");
    },

    reset() {
      this.name = "";
      this.clientId = "";
      this.secretId = "";
      this.tenantId == "";
    },

    async connectTeams() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.$store.commit("showLoadingBar");

      let redirect = location.origin;
      this.redirectUrl = `${redirect}/getAToken`;
      if (process.env.VUE_APP_PATH && process.env.VUE_APP_PATH.length > 1) {
        this.redirectUrl = `${redirect}/${process.env.VUE_APP_PATH}/getAToken`;
      }
      console.log(this.redirectUrl);

      let link = `https://login.microsoftonline.com/${this.tenantId}/oauth2/v2.0/authorize?client_id=${this.clientId}&response_type=code&redirect_uri=${this.redirectUrl}&response_mode=query&scope=User.Read&state=12345`;
      console.log(link);
      window.open(link, "_blank");

      const timer = setInterval(() => {
        const token = localStorage.getItem("teamsSignToken");
        if (token) {
          clearInterval(timer);
          localStorage.removeItem("teamsSignToken");
          this.createTeamsConnection(token);
        }
      });
    },

    async createTeamsConnection(token) {
      if (this.entry.id) {
        let input = {
          name: this.name,
        };
        const { error } = await connector.updateConnector(this.entry.id, input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        let input = {
          name: this.name,
          connectorType: "teams",
          clientid: this.clientId,
          clientsecret: this.secretId,
          code: token,
          tenantid: this.tenantId,
          redirecturl: this.redirectUrl,
        };
        const { error } = await connector.addConnection(input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      }

      if (this.entry.id) {
        this.$alert.success(`Connection updated`);
      } else {
        this.$alert.success(`Connection created`);
      }
      this.reset();
      this.closeSheet();
    },
  },
};
</script>
<style lang="scss">
#single-choice-field.singleChoice {
  .options-wrapper {
    border-top: none !important;
    padding-top: 0px !important;
  }
}
</style>
