<template>
  <Sheet
    :value="value"
    width="30vw"
    height="70vh"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title> Connect JIRA</template>

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <div class="field-wrapper">
            <ValidationProvider
              v-slot="{ errors }"
              name=""
              :rules="{ required: true }"
            >
              <SingleChoiceField
                v-model="jiraApp"
                is-mandatory
                :options="jiraTypes"
                :error="errors[0]"
                class="q-mb-lg singleChoice"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="API URL"
              :rules="{ required: true }"
            >
              <TextField
                v-model="apiUrl"
                label="API URL"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <ValidationProvider
              v-if="jiraApp === 'cloud'"
              v-slot="{ errors }"
              name="Email"
              :rules="{ required: true, email: true }"
            >
              <TextField
                v-model="email"
                label="Email"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="API Key"
              :rules="{ required: true }"
            >
              <PasswordField
                v-model="apiKey"
                label="API Key"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton
        :label="entry.id ? 'Update' : 'Connect'"
        color="primary"
        @click="createJiraConnection"
      />
    </template>
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import { integration } from "@/api/factory.js";
export default {
  name: "JiraConnect",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    SingleChoiceField,
    PasswordField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    entry: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      jiraApp: "cloud",
      jiraTypes: [
        {
          id: this.$nano.id(),
          label: "CLOUD",
          value: "cloud",
        },
        {
          id: this.$nano.id(),
          label: "ONPREMISE",
          value: "onpremise",
        },
      ],
      apiUrl: "",
      email: "",
      apiKey: "",
    };
  },

  watch: {
    entry: {
      immediate: true,
      handler() {
        this.reset();
        if (this.entry.id) {
          this.jiraApp = this.entry.email ? "cloud" : "onpremise";
          this.email = this.entry.email;
          this.apiKey = this.entry.apiKey;
          this.apiUrl = this.entry.apiUrl;
        }
      },
    },
  },

  methods: {
    closeSheet() {
      this.$emit("close");
    },

    reset() {
      this.jiraApp = "cloud";
      this.apiUrl = "";
      this.email = "";
      this.apiKey = "";
    },

    async createJiraConnection() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.$store.commit("showLoadingBar");
      let type = 0;
      if (this.jiraApp === "onpremise") {
        type = 1;
      } else if (this.jiraApp === "cloud") {
        type = 2;
      }
      let input = {
        apiUrl: this.apiUrl,
        apiKey: this.apiKey,
        email: this.email,
        cloudIntegrationId: this.entry.id || 0,
      };
      const { error } = await integration.createJiraConnection(type, input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        console.log(error);
        this.$alert.error(error);
        return;
      }
      if (this.entry.id) {
        this.$alert.success(`Connection updated`);
      } else {
        this.$alert.success(`Connection created`);
      }
      this.reset();
      this.closeSheet();
    },
  },
};
</script>
<style lang="scss">
#single-choice-field.singleChoice {
  .options-wrapper {
    border-top: none !important;
    padding-top: 0px !important;
  }
}
</style>
