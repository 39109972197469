<template>
  <div class="q-my-sm authorization">
    <!-- <BaseScrollbar class="q-pb-sm" height="calc(100vh - 456px)"> -->
    <div class="row">
      <div class="col-4 authorization-header">
        <div class="q-pa-sm">
          <div class="row items-center">
            <div class="col">
              <FormFieldLabel label="Type" />
            </div>
            <div class="col-5">
              <ValidationProvider
                v-slot="{ errors }"
                name="type"
                :rules="{ required: true }"
              >
                <SelectField
                  :value="type"
                  is-mandatory
                  :options="typeList"
                  :error="errors[0]"
                  @input="updateType"
                />
              </ValidationProvider>
            </div>
          </div>
          <template v-if="type !== 'No Auth'">
            <div class="description q-mt-sm row items-center justify-center">
              The authorization data will be automatically generated when you
              send the request.
            </div>
            <div
              v-if="
                type !== 'Inherit auth from parent' &&
                type !== 'API Key' &&
                type !== 'Bearer Token' &&
                type !== 'Basic Auth'
              "
              class="row items-center q-mb-sm"
            >
              <div class="col">
                <FormFieldLabel label="Add authorization data to" />
              </div>
              <div class="col-5">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Add authorization data to"
                  :rules="{ required: true }"
                >
                  <SelectField
                    v-model="addAuthorization"
                    is-mandatory
                    :options="addAuthorizationList"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div v-if="type === 'Digest Auth'" class="description q-mt-sm">
              By default, Postman will extract values from the received
              response, add it to the request, and retry it. Do you want to
              disable this?By default, Postman will extract values from the
              received response, add it to the request, and retry it. Do you
              want to disable this?
              <CheckboxField
                v-model="digestAuth.disableRequest"
                label="Yes, disable retring the request"
                description=""
                class="q-mt-sm"
              />
            </div>
          </template>
        </div>
      </div>
      <div class="col-7 q-ml-md">
        <template v-if="type === 'API Key'">
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Key" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Key"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="apiKey.key"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Value" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Value"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="apiKey.value"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Add to" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Add to"
                :rules="{ required: true }"
              >
                <SelectField
                  v-model="apiKey.addTo"
                  is-mandatory
                  :options="addToList"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
        </template>
        <template v-if="type === 'Bearer Token'">
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Token" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Token"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="bearerToken.token"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
        </template>
        <template v-if="type === 'No Auth'">
          <div class="row items-center justify-center description">
            This request does not use any authorization
          </div>
        </template>
        <template v-if="type === 'Inherit auth from parent'">
          <div class="row items-center justify-center description">
            This request is not inheriting any authorization helper at the
            moment. Save it in a collection to use the parent's authorization
            helper.
          </div>
        </template>
        <template v-if="type === 'JWT Bearer'">
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel
                label="Algorithm"
                tooltip="Algorithm used to generate the JWT signature"
              />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Algorithm"
                :rules="{ required: true }"
              >
                <SelectField
                  v-model="jwtBearer.algorithm"
                  is-mandatory
                  :options="algorithmList"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel
                label="Secret"
                tooltip="Secret used to generate the JWT signature"
              />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Secret"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="jwtBearer.secret"
                  is-mandatory
                  :error="errors[0]"
                />
                <CheckboxField
                  v-model="jwtBearer.secretBase64"
                  label="Secret Base64 encoded"
                  description=""
                  class="q-mt-sm"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel
                label="Payload"
                tooltip="A JSON object that contains the claims conveyed by the JWT"
              />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Payload"
                :rules="{ required: true }"
              >
                <TextAreaField
                  v-model="jwtBearer.payload"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div
              class="group-by col-auto q-mb-sm"
              @click="jwtBearer.advancedSettings = !jwtBearer.advancedSettings"
            >
              <div class="col-auto">
                <div class="groupLabel">Advanced configuration</div>
              </div>
              <BaseIcon
                :name="
                  jwtBearer.advancedSettings
                    ? 'mdi-chevron-down'
                    : 'mdi-chevron-up'
                "
                class="cursor-pointer q-ml-sm"
                @click="
                  jwtBearer.advancedSettings = !jwtBearer.advancedSettings
                "
              />
            </div>
          </div>
          <template v-if="jwtBearer.advancedSettings">
            <div class="row items-center q-mb-sm description">
              This request does not use any authorization
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel
                  label="Request header prefix"
                  tooltip="The prefix added to the authorization header in the request"
                />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Request header prefix"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="jwtBearer.advanced.reqHeaderPrefix"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel
                  label="JWT headers"
                  tooltip="These values will be appended to the JWT headers"
                />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="JWT headers"
                  :rules="{ required: true }"
                >
                  <TextAreaField
                    v-model="jwtBearer.advanced.JWTHeaders"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
                <div class="description">
                  Headers specific to the algorithm are added automatically.
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-if="type === 'Basic Auth'">
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Username" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Username"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="basicAuth.username"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Password" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Password"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="basicAuth.password"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
        </template>
        <template v-if="type === 'Digest Auth'">
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Username" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Username"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="digestAuth.username"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Password" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Password"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="digestAuth.password"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div
              class="group-by col-auto q-mb-sm"
              @click="
                digestAuth.advancedSettings = !digestAuth.advancedSettings
              "
            >
              <div class="col-auto">
                <div class="groupLabel">Advanced configuration</div>
              </div>
              <BaseIcon
                :name="
                  digestAuth.advancedSettings
                    ? 'mdi-chevron-down'
                    : 'mdi-chevron-up'
                "
                class="cursor-pointer q-ml-sm"
                @click="
                  digestAuth.advancedSettings = !digestAuth.advancedSettings
                "
              />
            </div>
          </div>
          <template v-if="digestAuth.advancedSettings">
            <div class="row items-center q-mb-sm description">
              Postman auto-generates default values for some of these fields
              unless a value is specified.
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Realm" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Realm"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="digestAuth.advanced.realm"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Nonce" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nonce"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="digestAuth.advanced.nonce"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Algorithm" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Algorithm"
                  :rules="{ required: true }"
                >
                  <SelectField
                    v-model="digestAuth.advanced.algorithm"
                    is-mandatory
                    :options="digestAuthAlgorithmList"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="qop" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="qop"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="digestAuth.advanced.qop"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Nonce Count" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nonce Count"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="digestAuth.advanced.nonceCount"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Client Nonce" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Client Nonce"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="digestAuth.advanced.clientNonce"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Opaque" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Opaque"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="digestAuth.advanced.opaque"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
          </template>
        </template>
        <template v-if="type === 'OAuth 1.0'">
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Signature Method" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Signature Method"
                :rules="{ required: true }"
              >
                <SelectField
                  v-model="auth1.signatureMethod"
                  is-mandatory
                  :options="signatureMethodList"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Consumer Key" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Consumer Key"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="auth1.consumerKey"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Consumer Secret" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Consumer Secret"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="auth1.consumerSecret"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Access Token" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Access Token"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="auth1.accessToken"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Token Secret" />
            </div>
            <div class="col-4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Token Secret"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="auth1.tokenSecret"
                  is-mandatory
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div
              class="group-by col-auto q-mb-sm"
              @click="auth1.advancedSettings = !auth1.advancedSettings"
            >
              <div class="col-auto">
                <div class="groupLabel">Advanced configuration</div>
              </div>
              <BaseIcon
                :name="
                  auth1.advancedSettings ? 'mdi-chevron-down' : 'mdi-chevron-up'
                "
                class="cursor-pointer q-ml-sm"
                @click="auth1.advancedSettings = !auth1.advancedSettings"
              />
            </div>
          </div>
          <template v-if="auth1.advancedSettings">
            <div class="row items-center q-mb-sm description">
              Postman auto-generates default values for some of these fields
              unless a value is specified.
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Callback URL" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Callback URL"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.callbackURL"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Verifier" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Verifier"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.verifier"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Timestamp" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Timestamp"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.timestamp"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Nonce" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nonce"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.nonce"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Version" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Version"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.version"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm border-bottom">
              <div class="col-3">
                <FormFieldLabel label="Realm" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Realm"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.Realm"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <BaseSeparator class="q-my-sm" />

            <div class="q-mb-sm">
              <CheckboxField
                v-model="auth1.advanced.bodyHash"
                label="Include body hash"
                description=""
                class="q-mt-sm"
              />
              <CheckboxField
                v-model="auth1.advanced.emptyParams"
                label="Add empty parameters to signature"
                description=""
                class="q-mt-md"
              />
            </div>
          </template>
        </template>

        <template v-if="type === 'oauth2'">
          <!-- <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Token" />
            </div>
            <div class="col-4">
              <TextField v-model="auth2.token" is-mandatory />
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Header Prefix" />
            </div>
            <div class="col-4">
              <TextField v-model="auth1.headerPrefix" />
            </div>
          </div> -->
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Token Name" />
            </div>
            <div class="col-4">
              <TextField v-model="auth2.tokenName" />
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Grant Type" />
            </div>
            <div class="col-4">
              <SelectField v-model="auth2.grantType" :options="grantTypes" />
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Access Token URL" />
            </div>
            <div class="col-4">
              <TextField v-model="auth2.accessTokenURL" />
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Client ID" />
            </div>
            <div class="col-4">
              <TextField v-model="auth2.clientId" />
            </div>
          </div>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Client Secret" />
            </div>
            <div class="col-4">
              <TextField v-model="auth2.clientSecret" />
            </div>
          </div>
          <template v-if="auth2.grantType === 'password'">
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Username" />
              </div>
              <div class="col-4">
                <TextField v-model="auth2.username" />
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Password" />
              </div>
              <div class="col-4">
                <PasswordField v-model="auth2.password" />
              </div>
            </div>
          </template>
          <div class="row items-center q-mb-sm">
            <div class="col-3">
              <FormFieldLabel label="Scope" />
            </div>
            <div class="col-4">
              <TextField v-model="auth2.scope" />
            </div>
          </div>

          <div class="row">
            <div
              class="group-by col-auto q-mb-sm"
              @click="auth1.advancedSettings = !auth1.advancedSettings"
            >
              <div class="col-auto">
                <div class="groupLabel">Advanced configuration</div>
              </div>
              <BaseIcon
                :name="
                  auth1.advancedSettings ? 'mdi-chevron-down' : 'mdi-chevron-up'
                "
                class="cursor-pointer q-ml-sm"
                @click="auth1.advancedSettings = !auth1.advancedSettings"
              />
            </div>
          </div>
          <template v-if="auth1.advancedSettings">
            <div class="row items-center q-mb-sm description">
              Postman auto-generates default values for some of these fields
              unless a value is specified.
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Callback URL" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Callback URL"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.callbackURL"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Verifier" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Verifier"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.verifier"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Timestamp" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Timestamp"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.timestamp"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Nonce" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nonce"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.nonce"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm">
              <div class="col-3">
                <FormFieldLabel label="Version" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Version"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.version"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="row items-center q-mb-sm border-bottom">
              <div class="col-3">
                <FormFieldLabel label="Realm" />
              </div>
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Realm"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="auth1.advanced.Realm"
                    is-mandatory
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>
            <BaseSeparator class="q-my-sm" />

            <div class="q-mb-sm">
              <CheckboxField
                v-model="auth1.advanced.bodyHash"
                label="Include body hash"
                description=""
                class="q-mt-sm"
              />
              <CheckboxField
                v-model="auth1.advanced.emptyParams"
                label="Add empty parameters to signature"
                description=""
                class="q-mt-md"
              />
            </div>
          </template>
        </template>
      </div>
    </div>
    <!-- </BaseScrollbar> -->
    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField";
export default {
  name: "Authorization",

  components: {
    FormFieldLabel,
    FormFieldError,
    ValidationProvider,
    SelectField,
    TextField,
    CheckboxField,
    TextAreaField,
    PasswordField,
  },

  props: {
    auth2: {
      type: Object,
      default: () => {},
    },

    type: {
      type: String,
      default: "Inherit auth from parent",
    },
  },

  data() {
    return {
      typeList: [
        {
          id: this.$nano.id(),
          label: "Inherit auth from parent",
          value: "Inherit auth from parent",
        },
        {
          id: this.$nano.id(),
          label: "No Auth",
          value: "No Auth",
        },
        {
          id: this.$nano.id(),
          label: "Basic Auth",
          value: "Basic Auth",
        },
        {
          id: this.$nano.id(),
          label: "Bearer Token",
          value: "Bearer Token",
        },
        {
          id: this.$nano.id(),
          label: "JWT Bearer",
          value: "JWT Bearer",
        },
        {
          id: this.$nano.id(),
          label: "Digest Auth",
          value: "Digest Auth",
        },
        {
          id: this.$nano.id(),
          label: "OAuth 1.0",
          value: "OAuth 1.0",
        },
        {
          id: this.$nano.id(),
          label: "OAuth 2.0",
          value: "oauth2",
        },
        {
          id: this.$nano.id(),
          label: "API Key",
          value: "API Key",
        },
      ],
      // type: "Inherit auth from parent",
      addAuthorization: "Request Header",
      addAuthorizationList: [
        {
          id: this.$nano.id(),
          label: "Request Header",
          value: "Request Header",
        },
        {
          id: this.$nano.id(),
          label: "Query Params",
          value: "Query Params",
        },
      ],
      error: "",
      apiKey: {
        addTo: "Header",
        value: "",
        key: "",
      },
      addToList: [
        {
          id: this.$nano.id(),
          label: "Header",
          value: "Header",
        },
        {
          id: this.$nano.id(),
          label: "Query Params",
          value: "Query Params",
        },
      ],
      bearerToken: {
        token: "",
      },
      jwtBearer: {
        algorithm: "",
        secret: "",
        secretBase64: false,
        payload: "",
        advanced: {
          reqHeaderPrefix: "",
          JWTHeaders: "",
        },
        advancedSettings: false,
      },
      algorithmList: [
        {
          id: this.$nano.id(),
          label: "HS256",
          value: "HS256",
        },
        {
          id: this.$nano.id(),
          label: "HS384",
          value: "HS384",
        },
        {
          id: this.$nano.id(),
          label: "HS512",
          value: "HS512",
        },
        {
          id: this.$nano.id(),
          label: "RS256",
          value: "RS256",
        },
        {
          id: this.$nano.id(),
          label: "RS512",
          value: "RS512",
        },
        {
          id: this.$nano.id(),
          label: "PS256",
          value: "PS256",
        },
        {
          id: this.$nano.id(),
          label: "PS384",
          value: "PS384",
        },
        {
          id: this.$nano.id(),
          label: "PS512",
          value: "PS512",
        },
        {
          id: this.$nano.id(),
          label: "ES384",
          value: "ES384",
        },
        {
          id: this.$nano.id(),
          label: "ES512",
          value: "ES512",
        },
      ],
      basicAuth: {
        username: "",
        Password: "",
      },
      digestAuth: {
        username: "",
        Password: "",
        advanced: {
          realm: "",
          nonce: "",
          algorithm: "",
          qop: "",
          nonceCount: "",
          clientNonce: "",
          opaque: "",
        },
        advancedSettings: false,
        disableRequest: false,
      },
      digestAuthAlgorithmList: [
        {
          id: this.$nano.id(),
          label: "MD5",
          value: "MD5",
        },
        {
          id: this.$nano.id(),
          label: "MD5-sess",
          value: "MD5-sess",
        },
        {
          id: this.$nano.id(),
          label: "SHA-256",
          value: "SHA-256",
        },
        {
          id: this.$nano.id(),
          label: "SHA-256-sess",
          value: "SHA-256-sess",
        },
        {
          id: this.$nano.id(),
          label: "SHA-512-256",
          value: "SHA-512-256",
        },
        {
          id: this.$nano.id(),
          label: "SHA-512-256-sess",
          value: "SHA-512-256-sess",
        },
      ],
      auth1: {
        signatureMethod: "",
        consumerKey: "",
        consumerSecret: "",
        accessToken: "",
        tokenSecret: "",
        advanced: {
          callbackURL: "",
          verifier: "",
          timestamp: "",
          nonce: "",
          version: "",
          realm: "",
          bodyHash: false,
          emptyParams: false,
        },
        advancedSettings: false,
      },
      signatureMethodList: [],
      // auth2: {
      //   tokenName: "",
      //   grantType: "",
      //   accessTokenURL: "",
      //   clientId: "",
      //   clientSecret: "",
      //   username: "",
      //   password: "",
      //   scope: "",
      // },
      grantTypes: [
        {
          id: this.$nano.id(),
          label: "Password credentials",
          value: "password",
        },
        {
          id: this.$nano.id(),
          label: "Client credentials",
          value: "client",
        },
      ],
    };
  },

  computed: {
    showInheritType() {
      if (this.type === "Inherit auth from parent") {
        return true;
      }
      return false;
    },

    hideNoAuth() {
      if (this.type === "No Auth") {
        return true;
      }
      return false;
    },
  },

  methods: {
    updateType(type) {
      this.$emit("update:type", type);
    },
  },
};
</script>

<style lang="scss">
// .authorization {
//   border-bottom: 1px solid var(--divider-color);
// }
.authorization-header {
  border-right: 1px solid var(--divider-color);
}

.type {
  width: 200px;
}

.description {
  color: var(--icon-color-inverted);
  margin-bottom: 4px;
  text-align: justify;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // line-clamp: 3;
  // -webkit-box-orient: vertical;
}

.group-by {
  //background-color: var(--deep-orange);
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  // border: 1px solid #e9a177;
  //color: white;
  cursor: pointer;
  .groupLabel {
    font-weight: 500;
  }
}
</style>
