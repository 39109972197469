<template>
  <div id="integration-browse-menu">
    <div class="row items-start q-col-gutter-md">
      <template v-for="(row, index) in connectApps">
        <div
          v-if="checkTenant || row.label !== 'Coming Soon'"
          :key="index"
          class="col-xs-6 col-sm-4 col-md-3 col-lg-3"
        >
          <div class="card">
            <div class="row items-center q-pa-md">
              <div class="col-12">
                <div class="row items-center">
                  <div class="image col-auto q-mr-sm">
                    <q-avatar
                      :color="row.color"
                      text-color="white"
                      :icon="row.icon"
                      size="38px"
                    />
                  </div>
                  <div
                    class="col text-base font-bold text-uppercase items-center"
                  >
                    {{ row.name }}
                  </div>
                  <BaseActionButton
                    v-if="row.label === 'Connected'"
                    icon="eva-more-vertical"
                    color="secondary"
                    is-flat
                    @click="openConnection(row.name)"
                  />
                </div>
              </div>
              <div class="col-12 q-pa-sm">
                <div class="description q-mt-xs">
                  {{ row.description }}
                </div>
              </div>
              <div class="col-12">
                <BaseButton
                  :label="row.label"
                  :icon="
                    row.label === 'Connected' ? 'mdi-check' : 'mdi-tray-plus'
                  "
                  class="q-ml-md"
                  style="float: right"
                  :color="
                    row.label === 'Connected'
                      ? darkTheme
                        ? 'secondary'
                        : 'positive'
                      : 'primary'
                  "
                  @click="connectApp(row)"
                ></BaseButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <JiraConnect
      :value="jiraConnectSheet"
      :entry="{}"
      @close="closeJira"
    ></JiraConnect>
    <SMTPConnect
      :value="smtpConnectSheet"
      :entry="{}"
      @close="closeSMTP"
    ></SMTPConnect>

    <APIConnection
      :value="apiConnectSheet"
      :entry="{}"
      @close="closeAPI"
    ></APIConnection>

    <SQLConnect
      :value="sqlConnectSheet"
      :entry="{}"
      @close="closeSQL"
    ></SQLConnect>

    <OracleConnect
      :value="oracleConnectSheet"
      :entry="{}"
      @close="closeOracle"
    ></OracleConnect>

    <SlackConnect :value="slackConnectSheet" :entry="{}" @close="closeSlack" />

    <TeamsConnect :value="teamsConnectSheet" :entry="{}" @close="closeTeams" />
  </div>
</template>
<script>
import { integration } from "@/api/factory.js";
import JiraConnect from "@/views/integration/views/integration-builder/components/JiraConnect";
import SMTPConnect from "@/views/integration/views/integration-builder/components/SMTPConnect";
import SQLConnect from "@/views/integration/views/integration-builder/components/SQLConnect";
import OracleConnect from "@/views/integration/views/integration-builder/components/OracleConnect";
import APIConnection from "@/views/integration/views/integration-builder/components/APIConnection";
import SlackConnect from "../../integration-builder/components/SlackConnect.vue";
import TeamsConnect from "../../integration-builder/components/TeamsConnect.vue";
import { mapState } from "vuex";

export default {
  name: "DetailsGridButton",

  components: {
    JiraConnect,
    SMTPConnect,
    APIConnection,
    SQLConnect,
    OracleConnect,
    SlackConnect,
    TeamsConnect,
  },

  props: {
    connectApps: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      jiraConnectSheet: false,
      checkAppInterval: null,
      smtpConnectSheet: false,
      apiConnectSheet: false,
      sqlConnectSheet: false,
      oracleConnectSheet: false,
      slackConnectSheet: false,
      teamsConnectSheet: false,
    };
  },
  computed: {
    ...mapState(["darkTheme"]),

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://trial.ezofis.com" &&
        this.$store.state.session.tenantId === 13
      ) {
        return false;
      }
      return true;
    },
  },

  async mounted() {
    if (this.$route.query.code) {
      this.connectJira(this.$route.query.code);
    }
  },

  methods: {
    closeJira() {
      this.jiraConnectSheet = false;
      this.$emit("refresh");
    },

    closeSMTP() {
      this.smtpConnectSheet = false;
      this.$emit("refresh");
    },

    connectApp(app) {
      if (app.label !== "Connected")
        switch (app.name) {
          case "jira":
            {
              this.jiraConnectSheet = true;
              // this.checkAppInterval = setInterval(
              //   () => this.checkAppConnection(),
              //   5000
              // );
              // let sessionId = new Date().toISOString();
              // let redirect_uri = "";
              // let origin = location.origin;
              // if (origin === "https://trial.ezofis.com") {
              //   redirect_uri = `https://trial.ezofis.com/app/integration/browse`;
              // } else if (origin === "https://demo.ezofis.com") {
              //   redirect_uri = `https://demo.ezofis.com/ezofis-app/integration/browse`;
              // } else {
              //   redirect_uri = `http://localhost:8080/integration/browse`;
              //   //redirect_uri = `https://demo.ezofis.com/ezofis-app/integration/browse`;
              // }
              // window.open(
              //   `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=yEL6OrQBm4wvw61cOmZNuGhS157lmI1O&scope=read%3Ajira-work%20write%3Ajira-work&redirect_uri=${redirect_uri}&state=${sessionId}&response_type=code&prompt=consent`,
              //   "_blank"
              // );
            }
            break;
          case "smtp":
            {
              this.smtpConnectSheet = true;
            }
            break;
          case "API":
            {
              this.apiConnectSheet = true;
            }
            break;
          case "Sql":
            {
              this.sqlConnectSheet = true;
            }
            break;
          case "oracle":
            {
              this.oracleConnectSheet = true;
            }
            break;
          case "slack":
            {
              this.slackConnectSheet = true;
            }
            break;
          case "teams":
            {
              this.teamsConnectSheet = true;
            }
            break;
          default:
            break;
        }
    },

    checkAppConnection() {
      let code = localStorage.getItem("code");
      if (code) {
        clearInterval(this.checkAppInterval);
        localStorage.removeItem("code");
        this.$emit("refresh");
      }
    },

    openConnection(app) {
      this.$router.push({
        name: "integration-builder",
        query: { app },
      });
    },

    async connectJira(code) {
      localStorage.setItem("code", code);
      let payload = {
        name: "jira",
        code: code,
      };
      const { error } = await integration.createConnectApps(payload);

      if (error) {
        this.$alert.error(error);
        return;
      }
      window.close();
    },

    closeAPI() {
      this.apiConnectSheet = false;
      this.$emit("refresh");
    },

    closeSQL() {
      this.sqlConnectSheet = false;
      this.$emit("refresh");
    },

    closeOracle() {
      this.oracleConnectSheet = false;
      this.$emit("refresh");
    },

    closeSlack() {
      this.slackConnectSheet = false;
      this.$emit("refresh");
    },

    closeTeams() {
      this.teamsConnectSheet = false;
      this.$emit("refresh");
    },
  },
};
</script>

<style lang="scss">
#integration-browse-menu {
  .card {
    background-color: var(--component-bg-color);
    border-radius: 6px;
    border: 1px solid var(--component-bg-color);
  }
  .card:hover {
    -moz-box-shadow: 0 0 10px #c6c6c6;
    -webkit-box-shadow: 0 0 10px #c6c6c6;
    box-shadow: 0 0 10px #c6c6c6;
  }

  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .description:hover {
    overflow: visible;
    white-space: normal;
    height: auto; /* just added this line */
  }
}
</style>
